@font-face {
  font-family: 'Mulish';
  src: url('../src/assets/fonts/Mulish/Mulish-VariableFont_wght.ttf') format('truetype');
}
body {
  font-family: Mulish ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes vibrate {
  0% { margin-left: 0rem; }
  12.5% { margin-left: 0.5rem; }
  25% { margin-left: -0.5rem; }
  37.5% { margin-left: 0rem; }
  50% { margin-left: 0.5rem; }
  70% { margin-left: -0.5rem; }
  85% { margin-left: 0.5rem; }
  100% { margin-left: 0rem; }
}
